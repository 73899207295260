import { useEffect } from "react"
import PropTypes from "prop-types"
import analytics from "../../services/analytics"
import { useLocation } from "react-router-dom"


export const sendPageView = (location) => {
  analytics.set({ page: location.pathname });
  analytics.pageview(location.pathname);
};

export const GAListener = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    sendPageView(location);
    // The effect is re-run whenever location changes, effectively "listening" for location changes
  }, [location]);

  return children;
};

GAListener.propTypes = {
  children: PropTypes.node,
  trackingId: PropTypes.string,
  history: PropTypes.shape({
    listen: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }),
}

export default GAListener
