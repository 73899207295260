import styled, { keyframes } from 'styled-components'
import div100vh from 'react-div-100vh'
import { Link } from 'react-router-dom'
import { Icons } from '../../assets'
import { TextColours, ButtonColours, OutlineColours } from '../../styles/colours'

export const ScanContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
`

const ellipsis = keyframes`
  to {
    width: 2.1rem;
  }
`

const ellipsisOffset = keyframes`
  to {
    width: 0rem;
  }
`

export const Ellipsis = styled.div`
  display: flex;
  justify-content: center;
`

export const EllipsisOffset = styled.div`
  width: 2.1rem;
  animation: ${ellipsisOffset} steps(4, end) 1.5s infinite;
  display: ${(props) => (props.visible ? 'block' : 'none')};
`

export const FYI = styled.p`
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
  color: ${TextColours.bodyText};
  margin: 0;
`

export const NavSpacer = styled.div`
  width: 100%;
  height: 6rem;
`

export const TopContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`

export const TopContainerContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 2rem;
`

export const Instructions = styled.div`
  display: flex;
`

export const InstructionText = styled.div`
  margin: auto;
  text-align: center;
  font-size: 2.6rem;
`

export const FingerprintReference = styled.div`
  margin: 0.2rem 0 0 1rem;
  width: 8.5rem;
  height: 8.5rem;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  background-image: url(${Icons.fingerprintIcon});
`

export const BottomContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 10%;
`

export const BottomTextContainer = styled.div`
  text-align: center;
  font-size: 1.3rem;
  line-height: 1.5;
  color: ${TextColours.bodyText};
`

export const HelpInstruction = styled.div`
  display: flex;
  align-items: center;
  visibility: ${(props) => (props.isVisible ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
  transition: opacity 1s linear;
`

export const Div100vh = styled(div100vh)`
  max-height: '100rvh';
`

export const HelpButton = styled(Link)`
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 50%;
  border: 1px solid ${ButtonColours.outlineBorder};
  content: '?';
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.4rem;
  margin-right: 10%;
  color: black;
  text-decoration: none;
`

export const Modal = styled.div`
  ${(props) => (props.open ? 'display:flex;' : 'display:none;')}
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: rgba(0,0,0,0.85);
  z-index: 1001;
  
  justify-content: center;
  align-items: center;
`

export const StyledContent = styled.div`
  line-height: 2.3rem;
  color: ${TextColours.bodyText};
  font-weight: 400;
  font-size: 1.5rem;
  margin-bottom: 2rem;
`

export const StyledHr = styled.hr`
  border: none;
  height: 1px;
  background-color: ${OutlineColours.dark};
  margin: 17px 0;
`

export const StyledOl = styled.ol`
  list-style: none;
  counter-reset: item;
  list-style-position: inside;
  padding-left: 0;
  width: 100%;
  display: inline-flex;
  flex-direction: column;

  & li {
    counter-increment: item;
    display: inline-flex;
  }

  & li::before {
    content: counter(item) '.';
    margin-right: 18px;
    color: ${TextColours.listItemNumbers};
  }
`

export const StatusTextContainer = styled.div`
  height: 2.9rem;
  overflow: hidden;
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
`

export const StatusText = styled.h2`
  color: ${TextColours.scanStatus};
  margin: 0;
  font-weight: bold;
  font-size: 1.7rem;
  line-height: 2.9rem;
  text-transform: uppercase;

  content: '${(props) => props.text}';

  transition: margin-top 0.5s;

  .sendingForVerification &:first-child {
    margin-top: -2.9rem;
  }

  &:nth-child(2) {
    color: ${TextColours.statusTextSending};
  }
`

export const StatisTextEllipsisHolder = styled.div`
  width: 2.1rem;
  display: inline-block;
  height: 2.9rem;
  text-align: left;
`

export const StatusTextEllipsis = styled.span`
  color: ${TextColours.scanStatus};
  margin: 0;
  font-weight: bold;
  font-size: 1.7rem;
  line-height: 2.9rem;
  display: inline-flex;
  overflow: hidden;
  content: '\u2026';
  vertical-align: bottom;
  animation: ${ellipsis} steps(4, end) 1.5s infinite;
  width: 0;
`

export const SendingSpinner = styled.div`
  width: 100px;
  height: 100px;
  border: 2px solid red;
`

export const Reticle = styled.div`
  opacity: ${(props) => (props.visible ? '1' : '0')};
  transition: opacity 0.5s linear;

  ${(props) => {
    let useWidth, useHeight;
    if (props.shape === 'circle' || props.shape === 'square') {
      useWidth = useHeight = Math.min(props.width, props.height);
    } else if (props.shape === 'oval' || props.shape === 'rectangle') {
      useWidth = props.width;
      useHeight = props.height;
    } else {
      throw new Error(`Unknown reticle shape: ${props.shape}`);
    }

    return `
      width: ${useWidth}px;
      height: ${useHeight}px;
    `;
  }}

  border: 2px dashed white;
  position: absolute;
  border-radius: ${(props) => (props.shape === 'square' || props.shape === 'rectangle' ? 0 : '50%')};
  box-sizing: border-box;
`;

const fadeOut = keyframes`
  0% {
    opacity: 0.3;
  }
  100% {
    opacity: 0;
  }
`

export const ReticleFingerprint = styled.div`
  opacity: ${(props) => (props.visible ? '0.3' : '0')};
  transition: opacity 0.5s linear;
  width: 90%;
  height: 90%;

  // border: 2px dashed white;

  position: absolute;
  box-sizing: border-box;
  background: url(${Icons.fingerprintIconWhite});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  top: 5%;
  left: 5%;
  // Apply the fade-out animation
  // animation: ${fadeOut} 5s linear forwards;
`

export const LoggedInPanel = styled.div`
  width: 100vw;
  height: 65px;
  position: absolute;
  left: 0;
  bottom: 0;
  box-sizing: border-box;
  background-color: lightgray;
  display: flex;
  align-items: center;
  font-size: 18px;
  padding: 20px;
  align-items: center;
  justify-content: space-between;
`
