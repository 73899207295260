// import mixpanel from "mixpanel-browser";

export default class TrackingManager {
  // constructor() {
  //   // mixpanel.init("94625420d7028ffc7619ad194aeb5add", { debug: true });
  // }

  track({ event, data }) {
    // mixpanel.identify(deviceId);
    // mixpanel.track(event, data);
  }
}
