import { AwsRum } from "aws-rum-web"

class MonitoringManager {
  constructor() {
    try {
      const config = {
        sessionSampleRate: 1,
        guestRoleArn: process.env.REACT_APP_CWR_GUEST_ROLE_ARN,
        identityPoolId: process.env.REACT_APP_CWR_IDENTITY_POOL_ID,
        endpoint: "https://dataplane.rum.ap-southeast-2.amazonaws.com",
        telemetries: ["performance", "errors", "http"],
        allowCookies: true,
        enableXRay: false,
      }

      const APPLICATION_ID = process.env.REACT_APP_CWR_APP_MONITOR_ID
      const APPLICATION_VERSION = "1.0.0"
      const APPLICATION_REGION = "ap-southeast-2"

      this.awsRum = new AwsRum(APPLICATION_ID, APPLICATION_VERSION, APPLICATION_REGION, config)
    } catch (error) {
      // Ignore errors thrown during CloudWatch RUM web client initialization
    }
  }

  record(props) {
    try {
      this.awsRum.addSessionAttributes({
        ...props,
      })
    } catch (error) {}
  }
}

const monitoringManager = new MonitoringManager()

export default monitoringManager
