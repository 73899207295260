import styled from "styled-components"

export const LineContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const Line = styled.div`
  width: 25px;
  height: 2px;
  background-color: black;
  margin: 2px 0;
  border-radius: 5px;
`
