import React from "react"
import PropTypes from "prop-types"
import { LineContainer, Line } from "./style"

const HamburgerMenu = ({ onClick }) => (
  <LineContainer onClick={onClick}>
    <Line />
    <Line />
    <Line />
  </LineContainer>
)

HamburgerMenu.propTypes = {
  onClick: PropTypes.func,
}

export default HamburgerMenu
