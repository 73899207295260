import styled from "styled-components"
import Dropdown from "../dropdown"
import { OutlineColours } from "../../styles/colours"

export const DropdownUI = styled(Dropdown)``

export const LabelUI = styled.div`
  display: inline-block;
  font-size: 13px;
  font-weight: bold;
  margin-left: 5px;
`
export const ItemUI = styled.div`
  display: flex;
  align-items: center;
`

export const ArrowIconUI = styled.img`
  height: 8px;
  width: 8px;
  float: right;
  margin-top: 6px;
`

export const MenuChevronDown = styled.div`
  width: 6px;
  height: 6px;
  border-left: 2px solid ${OutlineColours.menuChevron};
  border-bottom: 2px solid ${OutlineColours.menuChevron};
  transform: rotate(-45deg);
  margin-right: 0.3rem;
  margin-bottom: 0.3rem;
`

export const MenuChevronUp = styled.div`
  width: 6px;
  height: 6px;
  border-left: 2px solid ${OutlineColours.menuChevron};
  border-bottom: 2px solid ${OutlineColours.menuChevron};
  transform: rotate(135deg);
  margin-right: 0.3rem;
  margin-top: 0.3rem;
`
