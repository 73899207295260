import { createGlobalStyle } from "styled-components"

const GlobalStyle = createGlobalStyle`
    html {
        font-size: 62.5%;
    }

    body, input, textarea, button {
        font-family: ${props => props.theme.fontFamily}
    }

    h1 {
        font-weight: 300; 
        font-size: 3.2rem; 
    }

    h2 {
        font-weight: 300;
        font-size: 2.4rem;
    }

    h3 {
        font-weight: 600;
        font-size: 1.9rem;
    }

    h4 {
        font-weight: 600;
        font-size: 1.7rem;
    }

    p {
        font-weight: 400;
        font-size: 1.7rem;
        line-height: 2.6rem;
    }

    // overrides for Semantic UI Sidebar to preserve fixed elements and scrolling

    .pushable:not(body) {
        transform: none;
    }

    .pushable:not(body) > .ui.sidebar,
    .pushable:not(body) > .fixed,
    .pushable:not(body) > .pusher:after {
        position: fixed;
    }

    .pushable:not(body) > .pusher {
        width: 100vw;
    }

    hr[hidden] {
      display: block;
      visibility: hidden;
    }
`

export default GlobalStyle
