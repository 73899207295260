import React from "react"
import { useTranslation } from "react-i18next"

const Loader = () => {
  const { t } = useTranslation()
  return (
    <div>
      <div>{t("loading")}...</div>
    </div>
  )
}

export default Loader
