import React, { useState } from "react"
import { Icons } from "../../assets/index"
import SelectLanguage from "./../select_language"
import {
  BorderlessMenu,
  Logo,
  SelectLanguageContainer,
  Version,
  HamburgerMenuContainer,
  StyledLink,
  StyledHr,
  StyledLinkHighlight,
  SmallStyledLinkOut,
  StyledAccountInformation,
} from "./style"
import HamburgerMenu from "../hamburger-menu"
import NavbarOffset from "./navbar-offset"
// import Div100vh from "react-div-100vh";
import SidebarContext from "./context"
import StyledMenu from "./menu-styles"
import packageJson from "../../../package.json"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { getUrlString } from "../../services/utils"

const { useSelector } = require("react-redux")

const BurgerMenu = ({ children, visible, setVisible, onToggle, navbarOffset }) => {
  const { t } = useTranslation()

  const hideMenu = (state) => {
    setVisible(false)
  }

  const { accessToken, firstName, lastName } = useSelector((state) => state.account)

  return (
    <>
      <BorderlessMenu>
        <Link to={{ pathname: "/", search: `${getUrlString()}` }}>
          <Logo icon={Icons.laavaLogoIcon} />
        </Link>
        <SelectLanguageContainer>
          <SelectLanguage />
        </SelectLanguageContainer>
        <HamburgerMenuContainer>
          <HamburgerMenu onClick={onToggle} />
        </HamburgerMenuContainer>
        <StyledMenu
          right
          noOverlay
          isOpen={visible}
          width={"100%"}
          customBurgerIcon={false}
          onStateChange={(state) => {
            setVisible(state.isOpen)
          }}
        >
          <StyledLink
            to={{ pathname: "/" }}
            className={window.location.pathname === "/" ? "current" : ""}
            onClick={hideMenu}
          >
            <StyledLinkHighlight />
            {t("scanNow")}
          </StyledLink>

          <StyledLink to="/help" className={window.location.pathname === "/help" ? "current" : ""} onClick={hideMenu}>
            <StyledLinkHighlight />
            {t("needHelp")}
          </StyledLink>

          <StyledLink to="/about" className={window.location.pathname === "/about" ? "current" : ""} onClick={hideMenu}>
            <StyledLinkHighlight />
            {t("aboutLaava")}
          </StyledLink>

          {!accessToken && (
            <StyledLink
              to="/account"
              className={window.location.pathname === "/account" ? "current" : ""}
              onClick={hideMenu}
            >
              <StyledLinkHighlight />
              {t("logIn")}
            </StyledLink>
          )}

          {accessToken && (
            <StyledLink
              to="/account"
              className={window.location.pathname === "/account" ? "current" : ""}
              onClick={hideMenu}
            >
              <StyledLinkHighlight />
              {t("account")}
            </StyledLink>
          )}

          {accessToken && (
            <StyledAccountInformation>
              {t("loggedInAs")} {firstName} {lastName}
            </StyledAccountInformation>
          )}

          <StyledHr />

          <SmallStyledLinkOut
            href="https://app.termly.io/document/privacy-policy/30ea6723-f690-417c-ae15-65defd3ac0ca"
            target="_blank"
            onClick={hideMenu}
          >
            {t("privacyAndCookies")}
          </SmallStyledLinkOut>
          <SmallStyledLinkOut
            href="https://app.termly.io/document/terms-of-use-for-website/1a1b362d-11db-40d6-a4b6-2ea31a3b97c9"
            target="_blank"
            onClick={hideMenu}
          >
            {t("termsOfUse")}
          </SmallStyledLinkOut>

          <Version>Version {packageJson.version}</Version>
        </StyledMenu>
      </BorderlessMenu>
      <>
        {navbarOffset && <NavbarOffset />}
        {children}
      </>
    </>
  )
}

const NavBar = ({ children, navbarOffset }) => {
  const [visible, setVisible] = useState(false)

  const handleMenuButton = () => {
    setVisible(!visible)
  }

  return (
    <BurgerMenu onToggle={handleMenuButton} visible={visible} navbarOffset={navbarOffset} setVisible={setVisible}>
      <SidebarContext.Provider value={{ visible }}>{children}</SidebarContext.Provider>
    </BurgerMenu>
  )
}

export default NavBar
