// Categories
const BROWSER_CATEGORY = "Browser"
const TAG_DETECT_CATEGORY = "Tag detect"
const TAG_SCAN_CATEGORY = "Tag scan"
const USER_CATEGORY = "User"

// BROWSER_EVENT
export const BROWSER_EVENT = {
  category: BROWSER_CATEGORY,
  nonInteraction: true,
}
// BROWSER_EVENT_ACTIONS
export const BROWSER_SUPPORTED = "Supported browser"
export const BROWSER_UNSUPPORTED = "Unsupported browser"
export const BROWSER_ALLOWED_CAMERA_ACCESS = "Allowed camera access"
export const BROWSER_DISALLOWED_CAMERA_ACCESS = "Disallowed camera access"
export const BROWSER_ALLOWED_GEOLOCATION_ACCESS = "Allowed geolocation access"
export const BROWSER_DISALLOWED_GEOLOCATION_ACCESS = "Disallowed geolocation access"

// TAG_DETECT_EVENT
export const DETECT_EVENT = {
  category: TAG_DETECT_CATEGORY,
}
// TAG_DETECT_EVENT_ACTIONS
export const DETECT_SUCCEEDED = "Detect succeeded"
export const DETECT_FAILED = "Detect failed"
// TAG_DETECT_EVENT_TIMINGS
export const TIME_TO_TAG_DETECTED = {
  category: TAG_DETECT_CATEGORY,
  variable: "timeToTagDetected",
}

// TAG_SCAN_EVENT
export const SCAN_EVENT = {
  category: TAG_SCAN_CATEGORY,
}
// TAG_SCAN_EVENT_ACTIONS
export const SCAN_SUCCEEDED = "Scan succeeded"
export const SCAN_FAILED = "Scan failed"
export const SCAN_FAILED_NO_MATCH = "Scan failed with no match"
// TAG_SCAN_EVENT_TIMINGS
export const TIME_TO_TAG_SCANNED_AND_FOUND = {
  category: TAG_SCAN_CATEGORY,
  variable: "timeToTagScanned",
}

// USER_EVENT
export const USER_EVENT = {
  category: USER_CATEGORY,
}
// USER_EVENT_ACTIONS
export const USER_SCROLLED_HALF_OF_CONTENT = "User scrolled half of content"
// USER_EVENT_TIMINGS
export const TIME_TO_READY_TO_SCAN = {
  category: "Camera setup",
  variable: "timeToReadyToScan",
}
