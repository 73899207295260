import { useState, useEffect } from "react"
import { Browser, supported } from "../../services/browser"
import { BROWSER_EVENT, BROWSER_SUPPORTED, BROWSER_UNSUPPORTED } from "../../services/analytics/constants"
import analytics from "../../services/analytics"

const useSupportedBrowser = (initialValue = true) => {
  const OS = Browser.getOS()
  const supportedBrowser = supported[OS] && supported[OS][0]
  const [isBrowserSupported, setIsBrowserSupported] = useState(initialValue)

  useEffect(() => {
    try {
      Browser.checkSupport()
      analytics.event({
        ...BROWSER_EVENT,
        action: BROWSER_SUPPORTED,
      })
    } catch (e) {
      analytics.event({
        ...BROWSER_EVENT,
        action: BROWSER_UNSUPPORTED,
      })
      setIsBrowserSupported(false)
    }
  }, [isBrowserSupported])

  return { isBrowserSupported, supportedBrowser }
}

export default useSupportedBrowser
