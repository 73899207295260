import React from "react"
import { StyledButton, StyledImage, ButtonText, StyledArrow } from "./style"

const ModalButton = ({ text, colour, image, arrow, inverted, onClick }) => {
  return (
    <StyledButton onClick={onClick} colour={colour} inverted={inverted}>
      {image ? <StyledImage src={image} /> : <div />}
      <ButtonText colour={colour}>{text}</ButtonText>
      {arrow ? <StyledArrow src={arrow} /> : <div />}
    </StyledButton>
  )
}

export default ModalButton
