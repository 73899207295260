import styled from "styled-components"
import { Icons } from "../../assets"
import { TextColours } from "../../styles/colours"

export const StyledCloseButton = styled.div`
  background: url(${Icons.closeIcon});
  background-size: 100% 100%;
  width: 1.3rem;
  height: 1.3rem;
  position: absolute;
  top: 1rem;
  right: 1rem;
`

export const StyledInnerModal = styled.div`
  width: 90%;
`

export const StyledModalContent = styled.div`
  position: relative;
  background-color: white;
  bottom: 0;
  &&& {
    padding: 3rem;
    box-sizing: border-box;
  }
`

export const StyledHeader = styled.h2`
  &&& {
    margin-top: 0;
    line-height: 1.15;
    letter-spacing: -0.52px;
    color: #1c1e20;
  }
`

export const StyledContent = styled.p`
  line-height: 1.25;
  color: ${TextColours.bodyText};
`

export const Modal = styled.div`
  ${props => (props.open ? "display:flex;" : "display:none;")}
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: rgba(0,0,0,0.85);
  z-index: 1001;
  
  justify-content: center;
  align-items: center;
`

export const Icon = styled.div`
  width: 100px;
  height: 100px;
  background-image: url(${props => props.icon});
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 60%;
  padding-left: 0;
`
