export const getUrlString = () => window.location.search.substring(1)

export const getUrlSetting = settingName => {
  const queryString = window.location.search.substring(1)

  let result

  queryString.split("&").forEach(valuePair => {
    const key = valuePair.split("=")[0]
    const value = valuePair.split("=")[1]

    if (key === settingName) {
      result = value
    }
  })

  return result
}

export const resizeCanvas = ({ canvas, longestEdgeLength }) => {
  const { width: canvasWidth, height: canvasHeight } = canvas

  if ((canvasWidth > longestEdgeLength || canvasHeight > longestEdgeLength) && longestEdgeLength > 0) {
    const canvasOrientation = {
      PORTRAIT: "portrait",
      LANDSCAPE: "landscape",
    }

    const sourceCanvasOrientation =
      canvasWidth > canvasHeight ? canvasOrientation.LANDSCAPE : canvasOrientation.PORTRAIT

    const shorterEdgeScale =
      sourceCanvasOrientation === canvasOrientation.LANDSCAPE ? canvasHeight / canvasWidth : canvasWidth / canvasHeight

    let destinationCanvasWidth
    let destinationCanvasHeight

    switch (sourceCanvasOrientation) {
      case canvasOrientation.LANDSCAPE:
        destinationCanvasWidth = longestEdgeLength
        destinationCanvasHeight = Math.round(longestEdgeLength * shorterEdgeScale)
        break
      case canvasOrientation.PORTRAIT:
        destinationCanvasHeight = longestEdgeLength
        destinationCanvasWidth = Math.round(longestEdgeLength * shorterEdgeScale)
        break
      default:
    }

    const scaleCanvas = document.createElement("canvas")
    const scaleCtx = scaleCanvas.getContext("2d")
    scaleCtx.canvas.width = destinationCanvasWidth
    scaleCtx.canvas.height = destinationCanvasHeight

    scaleCtx.drawImage(canvas, 0, 0, destinationCanvasWidth, destinationCanvasHeight)
    return scaleCanvas
  }
  return canvas
}
