import ReactGA from "react-ga"

const isTracking = fn => (...args) => {
  if (process.env.REACT_APP_IS_TRACKING) {
    fn(...args)
  }
}

isTracking(ReactGA.initialize)(process.env.REACT_APP_GA_TRACKING_ID)

// ReactGA wrappers
const event = props => ReactGA.event(props)
const timing = props => ReactGA.timing({ ...props, value: parseInt(props.value) })
const set = props => ReactGA.set(props)
const pageview = props => ReactGA.pageview(props)

const analytics = {
  event: isTracking(event),
  timing: isTracking(timing),
  set: isTracking(set),
  pageview: isTracking(pageview),
}

export default analytics
