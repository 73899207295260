import React, { useState } from "react"
import { StyledDropdown, StyleLabel, StyleMenu, StyleMenuItem, StyledClickCatcher } from "./style"

const Dropdown = ({ className, icon, trigger, options, onOpen, onClose, onChange }) => {
  const [open, setOpen] = useState(false)

  const toggleOpen = () => {
    setOpen(!open)
  }

  const changeValue = key => {
    onChange(key)
    toggleOpen()
  }

  const menu = options.map(({ value, content }) => (
    <StyleMenuItem
      key={value}
      onClick={() => {
        changeValue(value)
      }}
    >
      {content}
    </StyleMenuItem>
  ))

  return (
    <StyledDropdown className={className} open={open}>
      <StyleLabel
        onClick={() => {
          toggleOpen()
        }}
      >
        {trigger}
        {icon}
      </StyleLabel>

      <StyleMenu visible={open}>{menu}</StyleMenu>
      <StyledClickCatcher
        visible={open}
        onClick={() => {
          toggleOpen()
        }}
      ></StyledClickCatcher>
    </StyledDropdown>
  )
}

export default Dropdown
