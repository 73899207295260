const laavaOrange = "#ff5500"
const darkOrange = "#ff8864"
const darkBlue = "#018ba8"
const midBlue = "#2ca5c3"
const lightGrey = "#eaeef2"
const midGrey = "#d3d7db"
const darkGrey = "#757575"
const black = "#000000"
const green = "#7bb240"
const white = "#ffffff"
const red = "#cc1f00"

// Unused colours
// const lightOrange = "#ff7300";
// const lightBlue = "#cde1e8";
// const ultraLightGrey = "#f5f7fa";

export const AlertColours = {
  green,
  red,
}

export const TextColours = {
  aboutBodyHeader: laavaOrange,
  links: laavaOrange,
  scanStatus: laavaOrange,
  bodyText: darkGrey,
  footerText: darkGrey,
  secondary: midGrey,
  tertiary: white,
  carouselTitle: black,
  help: black,
  underline: midBlue,
  navCurrentPageLink: laavaOrange,
  listItemNumbers: darkBlue,
  listBodyText: darkGrey,
  statusTextSending: laavaOrange,
  dark: black,
}

export const BackgroundColours = {
  dark: black,
  light: white,
  privacyAlertBackground: midGrey,
  introAnimationBackground: lightGrey,
  footerBackground: lightGrey,
  dropdownMenu: lightGrey,
  aboutGradientTop: laavaOrange,
  aboutGradientBottom: darkOrange,
  resultPageBackground: lightGrey,
  divider: lightGrey,
  timeout: lightGrey,
}

export const ButtonColours = {
  primary: laavaOrange,
  alertButton: darkBlue,
  outlineBorder: midGrey,
  modalButtonBlue: darkBlue,
}

export const OutlineColours = {
  dark: midGrey,
  scanBar: laavaOrange,
  menuChevron: darkGrey,
  spinner: laavaOrange,
}
