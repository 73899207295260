import styled from "styled-components"
import { TextColours } from "../../styles/colours"

export const StyledButton = styled.div`
    width: 100%
    border: 2px solid ${props => props.colour};
    border-radius: 50px;
    display: flex;
    padding: 1rem;
    align-items: center;
    justify-content: space-around;
    box-sizing: border-box;
    background-color: ${props => (props.inverted ? "transparent" : props.colour)};
    color: ${props => (props.inverted ? props.colour : TextColours.tertiary)}
`

export const StyledImage = styled.div`
  background-image: url(${props => props.src});
  height: 50px;
  width: 50px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`

export const StyledArrow = styled.div`
  background-image: url(${props => props.src});
  height: 20px;
  width: 20px;
  margin-right: 5px;
  background-size: contain;
  background-repeat: no-repeat;
`

export const ButtonText = styled.div`
  font-size: 1.6rem;
  flex-grow: 2;

  font-weight: 600;
  margin: 0 1.5rem;
  text-align: center;
`
