import React, { useState } from "react"
import PropTypes from "prop-types"
import ModalButton from "../modal-button"
import {
  Icon,
  Modal,
  StyledCloseButton,
  StyledHeader,
  StyledInnerModal,
  StyledContent,
  StyledModalContent,
} from "./style"

const CloseIcon = ({ handleClose }) => (
  <div className="close" onClick={handleClose}>
    <StyledCloseButton />
  </div>
)

const LaavaModal = ({
  history,
  title,
  body,
  buttonText,
  buttonColour,
  buttonImage,
  buttonArrow,
  buttonInverted,
  showCloseButton,
  showButton,
  children,
  icon,
}) => {
  const [open, setOpen] = useState(true)
  const handleClose = () => setOpen(false)

  const buttonData = {
    text: buttonText,
    colour: buttonColour,
    image: buttonImage,
    arrow: buttonArrow,
    inverted: buttonInverted,
  }
  return (
    <Modal open={open} closeIcon={<CloseIcon handleClose={handleClose} />} onClose={handleClose}>
      <StyledInnerModal>
        {showCloseButton && <CloseIcon handleClose={handleClose} />}
        <StyledModalContent>
          <Icon icon={icon} />
          <StyledHeader>{title}</StyledHeader>
          <StyledContent>{children}</StyledContent>

          {showButton && (
            <ModalButton
              {...buttonData}
              onClick={() => {
                window.location.reload()
              }}
            />
          )}
        </StyledModalContent>
      </StyledInnerModal>
    </Modal>
  )
}

LaavaModal.defaultProps = {
  title: "",
  body: "",
  button: "",
  buttonLink: "",
}

LaavaModal.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  button: PropTypes.string.isRequired,
  buttonLink: PropTypes.string.isRequired,
}

export default LaavaModal
