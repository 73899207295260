import React from 'react'
import { useTranslation } from 'react-i18next'
import Modal from '../../components/modal'
import { ButtonColours } from '../../styles/colours'
import { Browser, supported } from '../../services/browser'
import { StyledContent, StyledHr, StyledOl } from './style'
import { Icons } from '../../assets'

export const AllowCameraErrorModal = ({ browser }) => {
  const { t } = useTranslation()

  const os = Browser.getOS()

  return (
    <div>
      <Modal
        icon={Icons.cameraIconBlack}
        title={t('titleWarningAllowCamera')}
        showCloseButton={false}
        showButton={os === Object.keys(supported)[1] ? true : false}
        buttonText={t('refresh')}
        buttonColour={ButtonColours.primary}
        buttonInverted={false}
      >
        {os === Object.keys(supported)[1] ? (
          <StyledContent>{t('bodyWarningAllowCamera')}</StyledContent>
        ) : (
          <StyledContent>
            {t('thisWebscannerUsesTheCamera')}
            <StyledHr />
            {t('howUnblockCamera')}:
            <StyledOl>
              <li>{t('goToBrowserSettings')}</li>
              <li>{t('selectSiteSettings')}</li>
              <li>{t('selectCamera')}</li>
              <li>{t('selectWebsite')}</li>
              <li>{t('changePermission')}</li>
              <li>{t('refreshThePage')}</li>
            </StyledOl>
          </StyledContent>
        )}
      </Modal>
    </div>
  )
}

export const BrowserErrorModal = ({ browser }) => {
  const { t } = useTranslation()
  const os = Browser.getOS()

  return (
    <Modal
      icon={os === 'iOS' ? Icons.safariIcon : Icons.chromeIcon}
      title={t('titleWarningSupportedBrowser', { browser })}
      showCloseButton={false}
    >
      {t('bodyWarningSupportedBrowser')}
    </Modal>
  )
}
