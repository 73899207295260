import styled from "styled-components"
import { Link } from "react-router-dom"
import { TextColours, OutlineColours } from "../../styles/colours"

export const BorderlessMenu = styled.div`
  height: 6rem;
  display: flex;
  align-items: center;
  position: fixed;
  z-index: 101;
  background-color: white;
  width: 100%;
  top: 0;
`

export const Logo = styled.div`
  background-image: url(${(props) => props.icon});
  width: 50px;
  height: 50px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  flex: initial;
  margin-left: 20px;
`

export const SelectLanguageContainer = styled.div`
  margin-right: 20px;
  justify-content: flex-end;
  flex: auto;
  text-align: right;
`

export const NavbarOffset = styled.div`
  height: 6rem;
  min-height: 6rem;
`

export const HamburgerMenuContainer = styled.div`
  margin: 0 30px 0 10px;
`

export const Version = styled.span`
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 2rem;
`

export const StyledLink = styled(Link)`
  text-decoration: none;
  font-weight: 600;
  font-size: 2.4rem;
  margin-bottom: 4.2rem !important;
  text-align: left !important;
  &.current {
    color: ${TextColours.navCurrentPageLink} !important;
  }
`

export const StyledHr = styled.hr`
  width: 100%;
  margin-top: 20px;
  border: 0.5px solid ${OutlineColours.dark};
`

export const SmallStyledLink = styled(Link)`
  margin-top: 20px;
  text-decoration: underline;
  text-align: left !important;
`

export const SmallStyledLinkOut = styled.a`
  margin-top: 20px;
  text-decoration: underline;
  text-align: left !important;
`

export const StyledLinkHighlight = styled.div`
  width: 15px;
  height: 15px;

  display: inline-block;
  margin-right: 22px;
  border-radius: 50%;
  margin-left: -33px;

  .current & {
    background-color: ${TextColours.navCurrentPageLink};
  }
`

export const StyledAccountInformation = styled.div`
  text-align: left !important;
  font-size: 16px;
  margin: -37px 0 20px 3px;
`
