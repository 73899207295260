import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import EventManager from "../../services/event-manager"

import i18n from "../../i18n"

export const accountSlice = createSlice({
  name: "account",
  initialState: {
    accessToken: "",
    accessTokenExpiry: "",
    refreshToken: "",
    refreshTokenExpiry: "",
    firstName: "",
    lastName: "",
    emailAddress: "",
    isLoggingIn: false,
  },
  reducers: {
    setIsLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload
    },
    setAccessToken: (state, action) => {
      state.accessToken = action.payload
    },
    setAccessTokenExpiry: (state, action) => {
      state.accessTokenExpiry = action.payload
    },
    setRefreshToken: (state, action) => {
      state.refreshToken = action.payload
    },
    setRefreshTokenExpiry: (state, action) => {
      state.refreshTokenExpiry = action.payload
    },
    setFirstName: (state, action) => {
      state.firstName = action.payload
    },
    setLastName: (state, action) => {
      state.lastName = action.payload
    },
    setEmailAddress: (state, action) => {
      state.emailAddress = action.payload
    },
    setIsLoggingIn: (state, action) => {
      state.isLoggingIn = action.payload
    },
    checkCredentials: (state) => {
      console.log("CHECK CREDENTIALS")
      console.log(state.account.accessToken)
    },
  },
  extraReducers: (builder) => {
    builder.addCase(refreshCredentials.pending, (state, action) => {
      console.log("REFRESH CREDENTIALS PENDING")
    })

    builder.addCase(refreshCredentials.fulfilled, (state, action) => {
      console.log("REFRESH CREDENTIALS FULFILLED")
    })

    builder.addCase(refreshCredentials.rejected, (state, action) => {
      console.log("REFRESH CREDENTIALS REJECTED")
    })
  },
})

export const refreshCredentials = createAsyncThunk(
  "account/refreshCredentials",
  async (arg, { getState, dispatch }) => {
    const { refreshToken, emailAddress } = getState().account

    if (refreshToken) {
      try {
        axios({
          method: "post",
          url: `${process.env.REACT_APP_GATEWAY_URL}/auth/refresh`,
          headers: {
            "Content-Type": "application/json",
            refresh_token: refreshToken,
          },
          data: {
            email: emailAddress,
          },
        })
          .then((result) => {
            dispatch(setAccessToken(result.data.access_token))
            dispatch(setRefreshToken(result.data.refresh_token))

            dispatch(setAccessTokenExpiry(result.data.access_token_expiry))
            dispatch(setRefreshTokenExpiry(result.data.refresh_token_expiry))
          })
          .catch((error) => {
            console.log(error)
            // there was a problem refreshing the token, so just log the user out
            dispatch(logOut())
          })
      } catch (error) {
        console.log(error)
      }
    } else {
      // make sure things are cleared out
      dispatch(logOut())
    }
  }
)

export const logIn = (username, password) => (dispatch) => {
  dispatch(setIsLoggingIn(true))

  // try {
  axios({
    // Our AccMan service login endpoint
    method: "post",
    url: `${process.env.REACT_APP_GATEWAY_URL}/auth/login`,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      password: password,
      email: username,
    },
  })
    .then((result) => {
      let tokens = {
        access: {
          token: result.data.access_token,
          expiry: result.data.access_token_expiry,
        },
        refresh: {
          token: result.data.refresh_token,
          expiry: result.data.refresh_token_expiry,
        },
      }
      // Get profile data
      axios({
        method: "get",
        url: `${process.env.REACT_APP_GATEWAY_URL}/auth/profile`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${tokens.access.token}`,
        },
      }).then((result) => {
        const data = {
          user: result.data,
          tokens,
        }

        dispatch(setIsLoggingIn(false))
        dispatch(setIsLoggedIn(true))

        dispatch(setAccessToken(data.tokens.access.token))
        dispatch(setRefreshToken(data.tokens.refresh.token))

        dispatch(setAccessTokenExpiry(data.tokens.access.expiry))
        dispatch(setRefreshTokenExpiry(data.tokens.refresh.expiry))

        dispatch(setFirstName(data.user.first_name))
        dispatch(setLastName(data.user.last_name))

        dispatch(setEmailAddress(data.user.email))

        EventManager.dispatch(new CustomEvent('LoginEvent', {detail: {tokens: data.tokens, user: data.user}}))

        return data
      })
    })
    .catch((error) => {
      alert(i18n.t("thereWasAProblemLoggingIn"))
      console.error("could not log in")
      dispatch(setIsLoggingIn(false))
    })
}

export const logOut = () => (dispatch) => {
  dispatch(setIsLoggingIn(false))
  dispatch(setIsLoggedIn(false))

  dispatch(setAccessToken(null))
  dispatch(setRefreshToken(null))

  dispatch(setAccessTokenExpiry(null))
  dispatch(setRefreshTokenExpiry(null))

  dispatch(setFirstName(null))
  dispatch(setLastName(null))

  dispatch(setEmailAddress(null))
}

export const {
  setIsLoggedIn,
  setAccessToken,
  setAccessTokenExpiry,
  setRefreshToken,
  setRefreshTokenExpiry,
  setFirstName,
  setLastName,
  setEmailAddress,
  setIsLoggingIn,
} = accountSlice.actions
