import styled from "styled-components"
import { Link } from "react-router-dom"
import { TextColours, BackgroundColours } from "../../styles/colours"
import laavaLogo from "../../assets/laava_logo.svg"

export const StyledFooter = styled.div`
  background-color: ${BackgroundColours.footerBackground};
  padding: 32px;
  margin-top: auto;
`

export const StyledCopyright = styled.div`
  color: ${TextColours.footerText}
  font-size: 12px;
  text-align: center;
`

export const StyledLinks = styled.div`
  color: ${TextColours.footerText}
  font-size: 12px;
  text-align: center;
  padding-bottom: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const LaavaLogo = styled.div`
  background-image: url(${laavaLogo});
  width: 60px;
  height: 30px;
  background-repeat: no-repeat;
  background-position: center;
`

export const StyledLinkDiv = styled.div`
  display: inline-block;
`

export const StyledLink = styled(Link)`
  font-size: 12px;
  color: ${TextColours.footerText}
  text-decoration: underline;
  font-weight: bold;
`

export const StyledLinkOut = styled.a`
  font-size: 12px;
  color: ${TextColours.footerText}
  text-decoration: underline;
  font-weight: bold;
`
