import React from "react"
import { useTranslation } from "react-i18next"
import { StyledFooter, StyledLinks, StyledCopyright, StyledLinkDiv, LaavaLogo, StyledLinkOut } from "./style.js"

const Footer = () => {
  const { t } = useTranslation()
  const privacyAndCookies = "privacyAndCookies"
  const termsOfUse = "termsOfUse"
  const copyrightContent = "copyrightContent"
  const copyrightCompany = "©2021 Laava ID Pty Ltd."

  return (
    <StyledFooter>
      <StyledLinks>
        <LaavaLogo />
        <StyledLinkDiv>
          <StyledLinkOut
            href="https://app.termly.io/document/privacy-policy/30ea6723-f690-417c-ae15-65defd3ac0ca"
            target="_blank"
          >
            {t(privacyAndCookies)}
          </StyledLinkOut>
        </StyledLinkDiv>
        <StyledLinkDiv>
          <StyledLinkOut
            href="https://app.termly.io/document/terms-of-use-for-website/1a1b362d-11db-40d6-a4b6-2ea31a3b97c9"
            target="_blank"
          >
            {t(termsOfUse)}
          </StyledLinkOut>
        </StyledLinkDiv>
      </StyledLinks>
      <StyledCopyright>
        {t(copyrightContent)}
        <br />
        {copyrightCompany}
      </StyledCopyright>
    </StyledFooter>
  )
}

export default Footer
