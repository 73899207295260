import { v4 as uuidv4 } from "uuid"

export default class DeviceIdManager {
  getDeviceId() {
    const deviceId = window.localStorage.getItem("_dId")

    if (!deviceId) {
      const newDeviceId = uuidv4()
      window.localStorage.setItem("_dId", newDeviceId)
      return newDeviceId
    } else {
      return deviceId
    }
  }
}
