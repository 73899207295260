import Bowser from "bowser"

export const BrowserNotSupportedName = "BrowserNotSupported"

export const supported = {
  Android: ["Chrome"],
  iOS: ["Safari"],
}

class BrowserNotSupported extends Error {
  constructor(...params) {
    super(...params)
    this.name = BrowserNotSupportedName
  }
}

export const Browser = {
  checkSupport: () => {
    const bowser = Bowser.getParser(window.navigator.userAgent)
    const OS = bowser.getOS().name
    const osVersion = bowser.getOS().version
    const browser = bowser.getBrowser().name
    let OSSupported = supported[OS] !== undefined

    let browserSupported = OSSupported ? supported[OS].indexOf(browser) !== -1 : false

    // HAX
    if (OS === "iOS" && Number(`${osVersion.split(".")[0]}.${osVersion.split(".")[1]}`) >= 14.3) {
      OSSupported = browserSupported = true
    } else if (OS !== "iOS") {
      OSSupported = browserSupported = true
    }

    if (!OSSupported || !browserSupported) throw new BrowserNotSupported()
  },
  getOS: () => {
    const bowser = Bowser.getParser(window.navigator.userAgent)
    return bowser.getOS().name
  },

  getOSVersion: () => {
    const bowser = Bowser.getParser(window.navigator.userAgent)
    return bowser.getOS().version
  },
}
