import React from "react"
import { useTranslation } from "react-i18next"
import { Helmet } from "react-helmet"

const LaavaHelmet = () => {
  const { t } = useTranslation()
  return (
    <Helmet>
      <title>{t("pageTitle")}</title>
      <meta name="description" content={t("pageDescription")} />
    </Helmet>
  )
}

export default LaavaHelmet
