import React from "react"
import { DesktopPageContainer, ContentContainer, CopyContainer, ImageContainer } from "./style"

const DesktopPage = () => {
  return (
    <DesktopPageContainer>
      <ContentContainer>
        <CopyContainer>
          <h1>Please use your smartphone</h1>
          <p>Open this page in your smartphone browser to scan.</p>
        </CopyContainer>
        <ImageContainer></ImageContainer>
      </ContentContainer>
    </DesktopPageContainer>
  )
}

export default DesktopPage
