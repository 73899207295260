import styled from "styled-components"
import { BackgroundColours, ButtonColours } from "../../styles/colours.js"

const border = `solid 1px ${ButtonColours.outlineBorder}`

const width = "6.5rem"
const padding = "0.4em"

const radius = {
  open: "1.7rem",
  close: "5rem",
}
export const StyledDropdown = styled.div`
  position: relative;
  display: inline-block;
  border: ${border};
  border-radius: ${props => (props.open ? `${radius.open} ${radius.open} 0 0` : radius.close)};
  background-color: ${BackgroundColours.light};
  padding: ${padding};
  width: ${width};
  text-align: left;
  box-sizing: border-box;
`

export const StyleLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const StyleMenu = styled.div`
  position: absolute;
  top: 100%;
  left: -1px;
  background-color: ${BackgroundColours.dropdownMenu};
  visibility: ${props => (props.visible ? "visible" : "hidden")};
  padding: ${padding};
  width: ${width};
  border-radius: 0 0 ${radius.open} ${radius.open};
  border: ${border};
  box-sizing: border-box;
  z-index: 1
  
`

export const StyleMenuItem = styled.div`
  line-height: 2rem;
  vertical-align: bottom;
  margin: 5px 0px;
`

export const StyledClickCatcher = styled.div`
  visibility: ${props => (props.visible ? "visible" : "hidden")};
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
`
