import styled from "styled-components"
// eslint-disable-next-line no-unused-vars
import Inter from "inter-ui"
import desktopPageGraphic from "../../assets/desktop-page-graphic.svg"

export const DesktopPageContainer = styled.div`
  background-color: white;
  height: 100vh;
  width: 100vw;
  background-size: cover;
  background-position: center;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
`

export const ContentContainer = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
`

export const CopyContainer = styled.div`
  width: 400px;

  top: 0;
  display: flex;
  align-items: start;
  margin-right: 70px;
  flex-direction: column;
  justify-content: center;
  color: #000000;

  & h1 {
    font-family: Inter;
    font-size: 5rem;
    font-weight: 600;
    line-height: 1.26;
    margin-bottom: 0;
  }

  & h2 {
    color: #ff6600;
    text-transform: uppercase;
    font-size: 1.7rem;
    letter-spacing: 2px;
    font-weight: 600;
  }

  & p {
    font-family: Inter;
    font-size: 2.2rem;
    line-height: 1.83;
  }
`

export const ImageContainer = styled.div`
  background-image: url("${desktopPageGraphic}");
  width: 185px;
  height: 225px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`
