import laavaLogoIcon from "./laava_logo.svg"
import closeIcon from "./icon_close.svg"
import chromeIcon from "./icon_chrome.png"
import cameraIconBlack from "./icon_camera_b.svg"
import cameraIconWhite from "./icon_camera_w.svg"
import emailIcon from "./icon_email.svg"
import noIcon from "./icon_no.svg"
import cameraNoIcon from "./icon_camera_no.svg"
import alertIcon from "./icon_alert.svg"
import phoneIcon from "./icon_phone.svg"
import headerBgHelp from "./header_bg_help.png"
import chevronArrowRight from "./arrow_chevron_right.svg"
import chevronArrowLeft from "./arrow_chevron_left.svg"
import chevronArrowDown from "./arrow_chevron_down.svg"
import chevronArrowUp from "./arrow_chevron_up.svg"
import apple from "./apple.png"
import bottle from "./bottle.png"
import dna from "./dna.png"
import tickWhite from "./icon_tick_white.svg"
import awardWhite from "./icon_award_white.svg"
import awardRed from "./icon_award_red.svg"
import light from "./icon_light.svg"
import steady from "./icon_steady.svg"
import angle from "./icon_angle.svg"
import shadow from "./icon_shadow.svg"
import fingerprintReference from "./fingerprint_reference.png"
import symmetricalFingerprint from "./symmetrical_fp@3x.png"
import errorPattern from "./error_pattern.png"
import fingerprintIcon from "./icon_fingerprint.svg"
import fingerprintIconWhite from "./icon_fingerprint_white.svg"
import wifiIcon from "./icon_wifi.svg"
import safariIcon from "./icon_safari.png"

// logos for launch screen

import beauteLogo from "./logos/beaute.png"
import manukaLogo from "./logos/manuka.png"

export const Icons = {
  laavaLogoIcon,
  closeIcon,
  safariIcon,
  chromeIcon,
  cameraIconBlack,
  cameraIconWhite,
  emailIcon,
  noIcon,
  cameraNoIcon,
  alertIcon,
  phoneIcon,
  tickWhite,
  awardWhite,
  awardRed,
  light,
  steady,
  angle,
  shadow,
  fingerprintIcon,
  fingerprintIconWhite,
  wifiIcon,
}

export const Backgrounds = {
  headerBgHelp,
  errorPattern,
}

export const NavigationElements = {
  chevronArrowRight,
  chevronArrowDown,
  chevronArrowUp,
  chevronArrowLeft,
}

export const Images = {
  dna,
  bottle,
  apple,
  fingerprintReference,
  symmetricalFingerprint,
}

export const Logos = {
  beauteLogo,
  manukaLogo,
}
