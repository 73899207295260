/* eslint-disable no-undef */
const EventManager = {
  receive: (jsonString) => {
    try {
      const message = JSON.parse(jsonString)

      switch (message.type) {
        case 'TestEvent':
          alert(message.payload.testMessage)
          break
        default:
      }
    } catch (error) {
      console.error(error)
    }
  },
  dispatch: (event) => {
    const eventType = event.type
    const eventDetails = event.detail

    const eventData = {
      type: eventType,
      payload: JSON.stringify(eventDetails),
      time: Date.now().toString(),
    }

    // For iOS
    if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.callbackHandler) {
      window.webkit.messageHandlers.callbackHandler.postMessage(JSON.stringify(eventData))
    }
    // For Android
    else if (typeof app !== 'undefined' && app.callback) {
      app.callback(JSON.stringify(eventData))
    }
    // Handler not registered
    else {
      // console.error('The handler is not registered.')
    }

    // send the results out as a message if running in React Native
    window.ReactNativeWebView && window.ReactNativeWebView.postMessage(scanResultJSON)

    console.log({
      event: eventData,
    })
  },
}

export default EventManager
