import { stack as Menu } from "react-burger-menu"
import styled from "styled-components"

const StyledMenu = styled(Menu)`
  & {
    position: fixed;
    height: 100%;
    top: 0px;
    right: 0px;

    .bm-burger-button {
      position: fixed;
      width: 25px;
      height: 20px;
      right: 25px;
      top: 20px;
    }
    .bm-burger-bars {
      background: #373a47;
    }
    .bm-burger-barsHover {
      background: #a90000;
    }
    .bm-cross-button {
      height: 24px;
      width: 24px;
    }
    .bm-cross {
      background: #bdc3c7;
    }
    .bm-menu {
      background: #000000;
      padding: 2.5em 1.5em 0;
      font-size: 1.15em;
    }
    .bm-morph-shape {
      fill: #373a47;
    }
    .bm-item-list {
      color: #b8b7ad;
      padding: 0.8em;
      display: flex;
      flex-direction: column;
    }
    .bm-item {
      display: inline-block;
      text-align: center;
      color: white;
      margin-bottom: 2rem;
      outline: none;
    }
    .bm-overlay {
      background: rgba(0, 0, 0, 0.3);
    }
  }
`

export default StyledMenu
