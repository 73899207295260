const de = {
  pageTitle: "Laava-Scanner",
  pageDescription: "Scannen Sie Ihren Laava Smart Fingerprint™, um sicherzustellen, dass Ihr Produkt echt ist.",
  loading: "Wird geladen",
  startingScanner: "Scanner starten",
  privacyPolicy: "Durch die Nutzung von Laava stimmen Sie unseren zu",
  cookiesPolicy: "Cookie-Richtlinie",
  privacyAndCookies: "Datenschutz & Cookies",
  termsOfUse: "Nutzungsbedingungen",
  accept: "Annehmen",
  copyrightContent:
    "LAAVA, SMART FINGERPRINTS, FINGERABDRÜCKE, AUTHENTIFIZIERUNG VON DINGEN, der Laava-Check, das Laava-Logo und LAAVA ???? sind Warenzeichen von Laava ID Pty Ltd.",
  titleWarningAllowCamera: "Bitte erlauben Sie den Zugriff auf die Kamera.",
  bodyWarningAllowCamera:
    "Dieser Webscanner verwendet die Kamera, um Fingerabdrücke zu scannen. Erlauben Sie den Zugriff auf die Kamera, um es erneut zu versuchen.",
  titleWarningSupportedBrowser: "Bitte öffnen Sie {{browser}}, um Ihren Fingerabdruck zu scannen.",
  bodyWarningSupportedBrowser: "Der von Ihnen verwendete Browser wird derzeit nicht unterstützt.",
  activateTheCameraOn: "Aktiviere die Kamera auf {{device}}.",
  thisWebscannerUsesTheCamera: "Dieser Webscanner verwendet die Kamera, um Fingerabdrücke zu scannen.",
  scanNow: "Scanne jetzt",
  refresh: "Aktualisierung",
  refreshThePage: "Lade die Seite neu",
  loadAgain: "Laden Sie scan.laava.id erneut.",
  selectAllowWhenAsked: "Wählen Sie Zulassen, wenn Sie nach Kamerazugriff gefragt werden",
  whenAskedSelectAllow:
    "Wenn Sie gefragt werden, ob scan.laava.id auf die Kamera zugreifen möchte, wählen Sie Allow (Zulassen).",
  positionTheFingerprint: "Positionieren Sie den Code innerhalb des zu scannenden Rahmens",
  seeSupportedBrowsers: "Browser starten",
  supportedBrowsersPageTitle: "Dieser Scanner erfordert einen Browser mit Kameraunterstützung.",
  supportedBrowsersPageBody: "Das Betriebssystem Ihres Geräts bestimmt, welche Webbrowser Kameraunterstützung bieten.",
  howUnblockCamera: "So entsperren Sie die Kamera",
  help: "Hilfe",
  supportedBrowsersPageHelpBody: "Wenn Sie Fragen oder Bedenken haben, kontaktieren Sie uns bitte.",
  contactUs: "Kontaktiere uns",
  goToBrowserSettings: "Gehen Sie zu den Browsereinstellungen",
  findSettingBrowser:
    "Sie finden die Einstellungen, indem Sie auf die drei Punkte in der oberen rechten Ecke des Browsers tippen.",
  selectSiteSettings: "Wählen Sie Site-Einstellungen aus",
  foundAdvanced: "Diese finden Sie unter dem Untertitel Advanced.",
  selectCamera: "Kamera auswählen",
  selectWebsite: "Wählen Sie „https://scan.laava.id“.",
  changePermission: "Ändern Sie die Berechtigung für den Zugriff auf Ihre Kamera in „Erlauben“.",
  returnToWebscanner: "Kehren Sie zum Scannen zum Webscanner zurück.",
  lookForLaava: "Suchen Sie nach Laava.",
  simplyScan: "Einfach scannen.",
  shopWithAssurance: "Kaufen Sie mit Sicherheit ein.",
  laavaEnablesBrands:
    "Laava ermöglicht es Marken, Ihnen Gewissheit über die Qualität ihrer Produkte zu geben, indem sie eine neue, sichere Technologie verwenden, die mit dem CSIRO entwickelt wurde.",
  aboutLaava: "Über Lava",
  knowYouReGetting: "Sie wissen, dass Sie das Original bekommen und keinen Ersatz",
  laavaIsANewKind:
    "Laava ist eine neue Art von Barcode, der Ihnen helfen kann sicherzustellen, dass das Produkt, für das Sie bezahlen, echt ist, und Sie vor Imitationen von geringerer Qualität schützt.",
  everyFingerprint: "Jeder Fingerabdruck ist absolut einzigartig und anders als alle anderen",
  aFingerprintDoes:
    "Ein Fingerabdruck macht so viel mehr als nur anzuzeigen, welches Produkt oder welche Charge Sie in der Hand halten. Es ist ein individueller, absolut einzigartiger Fingerabdruck für genau das Produkt in Ihrer Hand.",
  seeBeyondTheLabel: "Sehen Sie über das Etikett hinaus und erfahren Sie mehr über Ihre Produkte",
  scanningAllowsYou:
    "Durch das Scannen können Sie mehr Informationen sehen als nur das, was auf dem Etikett steht. Informieren Sie sich über genau diesen Artikel, z. B. wo er hergestellt oder verpackt wurde.",
  ourCompany: "Unser Unternehmen",
  laavaWasFounded:
    "Laava wurde 2016 in Australien gegründet. Das Konzept und die Technologielösung wurden von weltweit führenden Experten in den Bereichen Optik, Blockchain, Marketing und Consumer Insights entwickelt.",
  needHelpScanning: "Benötigen Sie Hilfe beim Scannen?",
  youCanFindDetails:
    "Einzelheiten zum Scannen sowie Antworten auf häufig auftretende Probleme finden Sie in unserem Hilfeleitfaden.",
  getHelp: "Holen Sie sich Hilfe beim Scannen",
  howScan: "So scannen",
  problemsWithTheFingerprint: "Probleme mit dem Fingerabdruck",
  commonProblems: "Allgemeine Probleme",
  getResults:
    "Um die besten Ergebnisse zu erzielen, können Ihnen diese Tipps dabei helfen, einige häufig auftretende Probleme zu lösen, die die Qualität Ihres Scans beeinträchtigen können.",
  readyToScan: "Bereit, einen Fingerabdruck zu scannen?",
  needMoreHelp: "Benötigen Sie weitere Hilfe?",
  scanFingerprint: "Scannen Sie Ihren Fingerabdruck",
  bringFingerprint:
    "Bringen Sie den Fingerabdruck innerhalb der Kameraansicht in den Fokus und halten Sie ihn dort, bis der Fingerabdruck erkannt wird. Dies kann je nach Verbindungsgeschwindigkeit einen Moment dauern.",
  locateSmartFingerprint: "Suchen Sie Ihren Smart Fingerprint™",
  fingerprintsCanBeFound: "Fingerabdrücke sind auf einer Vielzahl von Alltagsprodukten zu finden.",
  alignTheFingerprint: "Richten Sie den Fingerabdruck mithilfe der Führung aus",
  alignTheGuide: "Richten Sie die Führung am inneren und äußeren Ring des Fingerprints aus.",
  snapPhoto: "Mach ein Foto",
  getYourFingerprint: "Fokussieren Sie Ihren Fingerabdruck und halten Sie Ihre Hand ruhig.",
  light: "Hell",
  likeAnyCamera:
    "Wie bei jeder Kamera erzielt man die besten Ergebnisse bei gutem Licht. Stellen Sie sicher, dass Ihr Fingerabdruck gut beleuchtet ist, und achten Sie auf Blendung.",
  keepYourHands: "Halten Sie Ihre Hände ruhig",
  tryKeepYourHand:
    "Versuchen Sie, Ihre Hand und Kamera beim Aufnehmen des Fotos ruhig zu halten, damit das Bild klar ist.",
  considerTheAngle: "Betrachten Sie den Winkel",
  scanYourFingerprint:
    "Scannen Sie Ihren Fingerabdruck in einer Position, die sich vor der Kamera befindet und den größten Teil der Ansicht ausfüllt (während Sie im Fokus bleiben).",
  watchOutForShadow: "Achten Sie auf Schatten",
  whenScanning:
    "Stellen Sie beim Scannen sicher, dass Ihr Telefon keinen dunklen Schatten auf den Fingerabdruck wirft.",
  haveAnyQuestion: "Haben Sie Fragen oder Feedback? Wir würden uns freuen, von Ihnen zu hören.",
  email: "Email",
  startingCamera: "Kamera starten",
  searching: "Scannen",
  analysing: "Analysieren",
  sending: "Senden",
  sendingForVerification: "Zur Überprüfung senden",
  mightTakeAFewMoments: "Es kann einige Augenblicke dauern",
  placeCodeWithinFrame: "Platzieren Sie den Code zum Scannen innerhalb des Rahmens",
  needHelp: "Brauchen Sie Hilfe?",
  googlePrivacyPolicy: "Google-Datenschutzrichtlinie",
  laavaPrivacy: "Laava-Datenschutz",
  laavaHasAPrivacy:
    "Laava hat ein {{ manifesto }}, das unsere Richtlinien erläutert, die Sie und Ihre Privatsphäre als Benutzer von Laava Fingerprints betreffen. Unabhängig davon haben wir auch eine {{ policy }}, die nur unter den begrenzten Umständen gilt, unter denen wir personenbezogene Daten erfassen müssen.",
  privacyManifesto: "Datenschutz-Manifest",
  corporatePrivacy: "Datenschutzrichtlinie des Unternehmens",
  laavaWebscannerTerm: "Geschäftsbedingungen von Laava – Webscanner",
  general: "Allgemein",
  websiteUse: "Website-Nutzung",
  intellectualProperty: "Geistiges Eigentum",
  limitionOfLiability: "Haftungsbeschränkung",
  indemnity: "Entschädigung",
  otherWebsites: "Andere Websites und Dritte",
  entireAgreement: "Ganze Vereinbarung",
  invalidity: "Nichtigkeit und Vollstreckbarkeit",
  privacy: "Privatsphäre",
  applicableLaw: "Anwendbares Recht",
  howToContact: "Wie man uns kontaktiert",
  termsP00:
    "Vielen Dank für den Zugriff auf die Laava-Website (die Website). Die Website ist Eigentum und wird betrieben von Laava ID Pty Ltd (ABN 99 617 775 578) (Laava).",
  termsP01:
    "Ihre Nutzung der Website unterliegt diesen Nutzungsbedingungen. Durch den Zugriff auf und die Nutzung der Website erklären Sie sich mit diesen Nutzungsbedingungen einverstanden.",
  termsP02:
    "Laava kann diese Nutzungsbedingungen jederzeit ganz oder teilweise ändern. Die aktuellsten Nutzungsbedingungen werden immer auf der Website veröffentlicht. Ihre nachfolgende oder fortgesetzte Nutzung der Website stellt Ihre Zustimmung zu allen Änderungen dar, die an diesen Nutzungsbedingungen vorgenommen werden. Wenn Sie diesen Nutzungsbedingungen oder daran vorgenommenen Änderungen widersprechen, sollten Sie die Nutzung der Website einstellen.",
  termsP03: "Diese Bedingungen wurden zuletzt am 26. November 2018 aktualisiert.",
  termsP1: "Sie dürfen die Website oder Inhalte nur für nichtkommerzielle Zwecke nutzen.",
  termsP2: "Die auf der Website enthaltenen Informationen dienen nur allgemeinen Informationszwecken.",
  termsP3:
    "Die Website und die auf der Website enthaltenen Informationen, Links, Dokumente oder zugehörigen Grafiken (zusammen der Inhalt) werden „wie besehen“ bereitgestellt . Jegliches Vertrauen, das Sie den Inhalten entgegenbringen, erfolgt ausschließlich auf Ihr eigenes Risiko. Sie müssen Ihre eigenen Nachforschungen anstellen, um festzustellen, ob der Inhalt für Ihre beabsichtigte Verwendung geeignet ist.",
  termsP4:
    "Obwohl Laava alle angemessenen Anstrengungen unternimmt, um sicherzustellen, dass die Website jederzeit verfügbar und voll funktionsfähig ist , kann Ihr Zugriff auf die Website im Falle eines Systemausfalls, Wartungsarbeiten oder aus Gründen, die außerhalb der Kontrolle von Laava liegen, ohne Vorankündigung ausgesetzt werden.",
  termsP5: "Laava kann vorübergehend oder dauerhaft Folgendes ändern oder einstellen:",
  termsP6: "Website oder Inhalte, Funktionen oder Dienste auf der Website; oder",
  termsP7: "der Pfad oder Ort eines Links,",
  termsP8:
    "jederzeit ohne Vorankündigung. Laava lehnt jegliche Haftung im Zusammenhang mit diesen oder ähnlichen Änderungen, Unterbrechungen oder Unterbrechungen der Website oder eines Teils der Website ab.",
  termsP9:
    "Laava behält sich das Recht vor, Ihren Zugriff auf die Website zu beenden und den weiteren Zugriff ohne Vorwarnung oder Benachrichtigung zu verhindern, wenn Sie gegen diese Nutzungsbedingungen verstoßen, gegen geltende Gesetze verstoßen oder verdächtige Aktivitäten begangen haben.",
  termsP10: "Bei der Nutzung der Website erklären Sie sich damit einverstanden, Folgendes nicht zu tun:",
  termsP11: "keine falschen, irreführenden oder irreführenden Informationen bereitstellen",
  termsP12: "Nutzung der Website in einer Weise, die gegen Gesetze oder Vorschriften verstößt oder verstoßen könnte",
  termsP13:
    "Nutzung der Website in einer Weise, die dazu führen kann, dass Laava gegen Gesetze oder Vorschriften verstößt",
  termsP14: "die Website in einer Weise zu nutzen, die sich nachteilig auf Laava oder andere Dritte auswirkt",
  termsP15:
    "Informationen über andere Benutzer der Website für andere als die von uns ausdrücklich genehmigten Zwecke zu sammeln",
  termsP16:
    "Maßnahmen ergreifen, die nach Ansicht von Laava eine unzumutbare Belastung für die Infrastruktur der Website darstellen;",
  termsP17:
    "Verbreitung eines Computervirus oder eines ähnlichen Programms oder Codes, der die Website stören oder deaktivieren kann",
  termsP18:
    "Verwenden Sie ein Gerät, eine Software oder einen Prozess oder andere automatisierte Mittel, um die Website oder Inhalte für beliebige Zwecke abzurufen, zu scrapen, zu kopieren oder zu indizieren; oder",
  termsP19:
    "Abrufen, Scrapen, Kopieren oder Indizieren der Website oder von Inhalten zum Zwecke der Erstellung einer Datenbank mit Immobilieninformationen.",
  termsP20:
    "Die Website und das gesamte geistige Eigentum an den Inhalten der Website, einschließlich (ohne Einschränkung) aller Texte, Grafiken, Fotos, Logos oder Handelsmarken, sind Eigentum von Laava oder an Laava lizenziert, sofern nicht anders angegeben.",
  termsP21:
    "Außer wenn dies zum Anzeigen des Inhalts auf der Website erforderlich ist, sofern gesetzlich zulässig oder wenn Sie die ausdrückliche schriftliche Genehmigung von Laava eingeholt haben, dürfen Sie diese nicht reproduzieren, neu kompilieren, dekompilieren, disassemblieren, zurückentwickeln, abgeleitete Werke davon erstellen, ausführen, modifizieren, anpassen , veröffentlichen, speichern, verwenden, elektronisch oder auf andere Weise übertragen, verteilen oder auf andere Weise verwerten, ganz oder teilweise die Website oder den Inhalt auf der Website für andere Zwecke als Ihre eigene persönliche, nicht kommerzielle Nutzung .",
  termsP22: "Ihre Nutzung der Website erfolgt auf eigene Gefahr.",
  termsP23:
    "Soweit gesetzlich zulässig, lehnt Laava alle ausdrücklichen oder stillschweigenden Zusicherungen, Garantien oder Gewährleistungen ab, einschließlich, aber nicht beschränkt auf:",
  termsP24:
    "die Vollständigkeit, Genauigkeit, Zuverlässigkeit, Eignung oder Verfügbarkeit in Bezug auf die Website oder den Inhalt",
  termsP25: "dass die Website und der Inhalt fehlerfrei sind oder dass Mängel behoben werden",
  termsP26: "dass Ihr Zugriff auf die Website ununterbrochen, sicher oder fehlerfrei ist",
  termsP27: "dass die Website frei von Viren oder anderen schädlichen Komponenten ist; und",
  termsP28: "die Qualität, Genauigkeit oder Zweckmäßigkeit verlinkter Websites oder Materialien Dritter .",
  termsP29:
    "Soweit gesetzlich zulässig, haften Laava und seine Direktoren, Mitarbeiter und Vertreter Ihnen gegenüber nicht für Verluste oder Schäden jeglicher Art (einschließlich Folgeschäden), unabhängig davon, ob diese aus Vertrag, unerlaubter Handlung, einschließlich Fahrlässigkeit, Gesetz oder anderweitig entstehen im Zusammenhang mit der Website oder den Inhalten.",
  termsP30:
    "Nichts in diesen Nutzungsbedingungen soll Rechte ausschließen, einschränken oder modifizieren, die Sie nach irgendeinem Gesetz haben (einschließlich des australischen Verbrauchergesetzes in Australien), die nicht durch Vereinbarung ausgeschlossen, eingeschränkt oder geändert werden dürfen. Wenn diese Nutzungsbedingungen dem australischen Verbrauchergesetz unterliegen, ist unsere Haftung Ihnen gegenüber für die Nichteinhaltung einer Verbrauchergarantie in Bezug auf Dienstleistungen auf die erneute Bereitstellung der Dienstleistungen oder die Zahlung für eine solche erneute Bereitstellung beschränkt.",
  termsP31:
    "Sie stellen Laava in Bezug auf jegliche Haftung frei, die Laava für Verluste, Verbindlichkeiten oder Schäden, Ausgaben, einschließlich, aber nicht beschränkt auf angemessene Anwalts- und Buchhaltungsgebühren, wie auch immer verursacht, entstehen, die Laava infolge Ihres Verstoßes gegen diese Nutzungsbedingungen entstehen oder Ihre Nutzung der Website.",
  termsP32: "Die Website kann Links zu Websites Dritter, Werbung oder Informationen auf diesen Websites enthalten.",
  termsP33:
    "Laava hat keine Kontrolle über die Art, den Inhalt und die Verfügbarkeit von Websites Dritter . Die Aufnahme von Links zu anderen Websites impliziert nicht unbedingt eine Förderung, Befürwortung oder Genehmigung dieser Websites oder der darin zum Ausdruck gebrachten Ansichten.",
  termsP34:
    "Laava übernimmt keine Verantwortung für den Inhalt dieser Websites. Jegliche Nutzung von Websites und Informationen Dritter erfolgt auf eigene Gefahr.",
  termsP35:
    "Diese Nutzungsbedingungen stellen die gesamte Vereinbarung zwischen Ihnen und Laava bezüglich Ihrer Nutzung und Ihres Zugriffs auf die Website und Ihrer Nutzung und Ihres Zugriffs auf die darin enthaltenen Inhalte dar. Kein anderer Begriff darf in diese Nutzungsbedingungen aufgenommen werden, es sei denn, er muss durch eine Gesetzgebung des Commonwealth of Australia oder eines Staates oder Territoriums von Australien aufgenommen werden. Alle stillschweigenden Bedingungen, außer denen, die gesetzlich impliziert sind und nicht ausdrücklich ausgeschlossen werden können, sind ausdrücklich ausgeschlossen.",
  termsP36:
    "Wenn eine Bestimmung dieser Nutzungsbedingungen nach dem Recht einer Gerichtsbarkeit ungültig ist, dann ist die Bestimmung in dieser Gerichtsbarkeit in dem Umfang durchsetzbar, in dem sie nicht ungültig ist, unabhängig davon, ob es sich um trennbare Bestimmungen handelt oder nicht.",
  termsP37:
    "Alle personenbezogenen Daten, die Laava von Ihnen erhebt, werden gemäß dem Privacy Act 1988 ( Cth ) und der {{policy}} behandelt.",
  termsP38:
    "Weitere Informationen darüber, wie Laava mit Ihren persönlichen Daten umgeht, wenn Sie Laava-Fingerabdrücke scannen, finden Sie unter {{manifesto}}",
  termsP39:
    "Laava behält sich das Recht vor, jederzeit und von Zeit zu Zeit den Website-Scanner oder Teile davon aus beliebigen Gründen zu ändern, vorübergehend oder dauerhaft einzustellen. Laava lehnt jegliche Haftung infolge einer Einstellung oder Unterbrechung des Website-Scanners oder eines Teils davon ab",
  termsP40: "Sie können Laava kontaktieren über:",
  contact: "Kontakt",
  privacyOfficer: "Datenschutzbeauftragter",
  address: "Die Anschrift",
  phone: "Telefon",
  laavaPrivacyManifesto: "Datenschutz-Manifest von Laava",
  privacyManifestoIntroduction: "Laava – Datenschutz-Manifest",
  ourApproach: "Unser Ansatz für Ihre Privatsphäre",
  whatInformationScan: "Welche Informationen werden gesammelt, wenn ich einen Laava-Fingerabdruck scanne?",
  whatInformationDetect: "Welche Informationen werden gesammelt, wenn wir verdächtige Aktivitäten erkennen?",
  howDoesLaava: "Wie verwendet Laava Cookies?",
  sharingInformation: "Informationen teilen",
  privacyManifestoP1:
    "Dieses Dokument ist unser Datenschutzmanifest, das unsere Richtlinien bezüglich der Erfassung von Informationen umreißt, wenn Sie einen Laava Smart Fingerprint mit einem Browser oder WeChat Laava-Scanner scannen.",
  privacyManifestoP2:
    "Wir haben einen separaten {{ link }}, der darlegt, wie wir mit Ihren personenbezogenen Daten in anderen geschäftlichen Situationen umgehen, z. B. wenn Sie sich um eine Stelle bei uns bewerben, wenn Sie ein potenzieller Investor, Vertreter eines Partners, Kunden, Lieferanten oder Dienstleister von uns, oder wenn Sie sich an unseren Helpdesk wenden oder eine Frage oder Anfrage an uns senden.",
  privacyManifestoP3:
    "Wir möchten keine Ihrer persönlichen Daten sammeln, wenn Sie Laava Smart Fingerprints scannen. Der Schutz Ihrer Privatsphäre ist unser Hauptanliegen, weshalb wir keine Namen, Logins , Telefonnummern, E-Mail-Adressen oder andere Informationen benötigen, die Sie persönlich identifizieren.",
  privacyManifestoP4:
    "Das Scannen von Laava Smart Fingerprints ist der Kern von allem, was wir tun. Wir möchten jedem, der einen Laava Smart Fingerprint scannt, eine sichere, private und sorgenfreie Umgebung bieten. Das Scannen von Laava Smart Fingerprints bedeutet keinen Ärger und keine neugierigen Blicke.",
  privacyManifestoP5:
    "Wir glauben, dass so viele Unternehmen in Bezug auf den Datenschutz der Verbraucher besser werden können, und wir möchten eine Vorreiterrolle einnehmen. Nur weil Sie Daten sammeln können, heißt das nicht, dass Sie es tun sollten.",
  privacyManifestoP6:
    "Wenn Sie einen Laava Smart Fingerprint scannen, erfassen wir ein Bild Ihres Scans und andere Scaninformationen oder Metadaten, die erforderlich sind, um den Laava Smart Fingerprint zu authentifizieren und seinen Statusdatensatz zu aktualisieren. Diese Metadaten können die URL, von der der Scan stammt, den Scan-Standort (normalerweise abgeleitet von einer IP-Adresse oder einem GPS-Standort) und die Zeit jedes Scans enthalten. Wir weisen bei jedem Scan auch eine anonymisierte Scanner-ID zu und speichern diese und können eingeschränkte Geräteinformationen wie Browser- und Kameraeinstellungen verwenden.",
  privacyManifestoP7:
    "Wichtig ist, dass alle Informationen, die wir sammeln, wenn Sie einen Laava Smart Fingerprint scannen, anonymisiert sind und nicht einfach verwendet werden können, um Sie zu identifizieren ( anonymisierte Informationen) . Wenn das Bild Ihres Scans Informationen enthält, die verwendet werden könnten, um Sie zu identifizieren, werden wir dies nicht versuchen.",
  privacyManifestoP8:
    "Wenn Sie einen Laava Smart Fingerprint in unserem WeChat -Miniprogramm scannen , unterliegen Sie auch der Datenschutzerklärung von WeChat.",
  privacyManifestoP9:
    "Um sicherzustellen, dass Laava ein wirksames Instrument gegen Fälschung und Betrug ist, und um Ihre Scanumgebung sicher zu halten und eine hohe Servicequalität, Leistung und Sicherheit aufrechtzuerhalten, verwenden wir eine Reihe von Methoden, um festzustellen, ob die Scanaktivität eines einzelnen Laava Smart Fingerprint ist misstrauisch. Wenn wir verdächtige Aktivitäten erkennen, können wir eine gründliche Analyse der Scan-Aktivität und der Metadaten durchführen und diese Informationen an unsere Kunden und Partner (zusammen Kunden) weitergeben .",
  privacyManifestoP10:
    "Laava verwendet auf seiner Website Browser-Cookies. Ein Browser-Cookie ist ein kleines Datenelement, das auf Ihrem Gerät gespeichert wird, um Websites dabei zu helfen, sich Dinge über Ihr Surfen zu merken. Cookies helfen uns zu erkennen, welche Teile unserer Website am beliebtesten sind, wodurch wir Ihre Erfahrung verbessern können.",
  privacyManifestoP11:
    "Zur Verbesserung unserer Website können bestimmte Informationen als statistische Informationen gesammelt werden, darunter IP-Adresse, Browsertyp, Sprache und Zugriffszeiten. Die auf unserer Website verwendeten Tools zeichnen Informationen auf eine Weise auf, die Sie nicht als Einzelperson identifizieren. Ihr Browser oder Gerät kann es Ihnen ermöglichen, Cookies zu blockieren, deren Verwendung einzuschränken oder zu entfernen.",
  privacyManifestoP12:
    "Wenn Sie Laava Smart Fingerprints mit einem browserbasierten Scanner scannen, werden möglicherweise Cookies verwendet, aber Ihr Scanverlauf wird nicht gespeichert.",
  privacyManifestoP13:
    "Unsere Website kann von Zeit zu Zeit auch Website-Tools von Drittanbietern verwenden, z. B. Google Analytics und Online-Umfragetools. Weitere Informationen zur Erhebung und Verarbeitung von Daten durch Google finden Sie in der Datenschutzerklärung von Google und deren Informationen unter {{ link }}",
  privacyManifestoP14:
    "Kunden sind offensichtlich daran interessiert, die mit ihren Artikeln verbundenen Laava Smart Fingerprints-Scanaufzeichnungen zu analysieren. Wir teilen Scan-Informationen über Laava Smart Fingerprints mit Kunden, normalerweise in aggregierter Form. Wenn wir Informationen mit Kunden teilen, teilen wir ihnen nur mit, was sie wissen müssen, z. B. wo, wann und wie oft ihr Laava Smart Fingerprint gescannt wurde. Da wir Ihre personenbezogenen Daten nicht erfassen, können wir diese nicht mit ihnen teilen.",
  privacyManifestoP15:
    "Wir ermöglichen unseren Kunden auch, mit Ihnen persönlich in Kontakt zu treten, wenn Sie einen Laava Smart Fingerprint scannen. Im Rahmen dieses Engagements können Kunden personenbezogene Daten von Ihnen anfordern. Wir finden das großartig, aber bei Laava erfolgt die Weitergabe personenbezogener Daten immer auf transparenter und Opt-in-Basis. Sie haben immer die Kontrolle und können wählen, was Sie teilen.",
  privacyManifestoP16:
    "Laava wird Ihre persönlichen Daten nicht sehen oder sammeln, stattdessen werden alle persönlichen Daten, die Sie teilen, von unseren Kunden in Übereinstimmung mit ihren Datenschutzrichtlinien gesammelt und behandelt, die Sie akzeptieren müssen.",
  privacyManifestoP17:
    "Laava kann alle von Ihnen bereitgestellten Informationen weitergeben, wenn es der Ansicht ist, dass die Freigabe zur Einhaltung des Gesetzes angemessen ist, um Gerichtsverfahren zu erleichtern oder die Rechte, das Eigentum oder die Sicherheit von Laava, den Benutzern dieser Website und anderen zu schützen.",
  laavaPrivacyPolicy: "Laava-Datenschutzrichtlinie",
  privacyPolicyT1: "Über diese Datenschutzrichtlinie des Unternehmens",
  privacyPolicyT2: "Wann erfassen wir Ihre personenbezogenen Daten?",
  privacyPolicyT3: "Welche personenbezogenen Daten erfassen und verarbeiten wir?",
  privacyPolicyT4: "Warum erfassen wir Ihre personenbezogenen Daten?",
  privacyPolicyT5: "An wen können wir Ihre personenbezogenen Daten weitergeben?",
  privacyPolicyT6: "Was ist, wenn wir Ihre personenbezogenen Daten nicht erfassen können?",
  privacyPolicyT7: "Besucher unserer Website und Cookies",
  privacyPolicyT8: "Wie schützen wir Ihre personenbezogenen Daten?",
  privacyPolicyT9: "Direktmarketing-Kommunikation",
  privacyPolicyT10: "Wie können Sie auf Ihre personenbezogenen Daten zugreifen und diese korrigieren?",
  privacyPolicyT11: "Wie können Sie eine Datenschutzbeschwerde einreichen?",
  privacyPolicyT12: "Wie man uns kontaktiert",
  privacyPolicyP1:
    "Wir glauben, dass so viele Unternehmen in Bezug auf den Datenschutz der Verbraucher besser werden können, und wir möchten eine Vorreiterrolle einnehmen. Nur weil Sie Daten sammeln können, heißt das nicht, dass Sie es tun sollten. Wir schätzen und respektieren Ihre Privatsphäre und nehmen den Schutz Ihrer persönlichen Daten ernst.",
  privacyPolicyP2:
    "In dieser Datenschutzrichtlinie des Unternehmens geht es NICHT darum, was mit Ihnen oder Ihren persönlichen Daten passiert, wenn Sie einen Laava-Fingerabdruck scannen. Unsere Richtlinie, beim Scannen von Laava-Fingerabdrücken keine personenbezogenen Daten zu sammeln, wird in unserem separaten {{ Link }} behandelt.",
  privacyPolicyP3:
    "Diese Datenschutzrichtlinie des Unternehmens legt fest, wie Laava ID Pty Ltd (ABN 99617 775 578) (Laava, wir, unser, uns) mit Ihren personenbezogenen Daten unter den begrenzten Umständen umgeht, unter denen wir personenbezogene Daten erfassen müssen, z bei uns arbeiten oder in Laava investieren oder mit Laava zusammenarbeiten möchten (als Vertreter eines Partners, Kunden, Lieferanten oder Dienstleisters), oder wenn Sie sich an unseren Helpdesk wenden oder eine Anfrage stellen.",
  privacyPolicyP4:
    "Es beschreibt die personenbezogenen Daten, die wir über Sie sammeln, wann und wie wir sie verwenden. Es beschreibt auch Ihre Datenschutzrechte als Einzelperson und wie Sie uns kontaktieren können, wenn Sie Bedenken hinsichtlich des Datenschutzes haben. Diese Datenschutzrichtlinie kann sich von Zeit zu Zeit ändern und Sie sollten sie daher regelmäßig überprüfen.",
  privacyPolicyP5:
    "Wie in unserem Datenschutzmanifest dargelegt, erfassen wir keine personenbezogenen Daten im Zusammenhang mit dem Scannen eines Laava-Fingerabdrucks.",
  privacyPolicyP6:
    "Wir sehen oder erfassen Ihre personenbezogenen Daten auch nicht, wenn Sie sich dafür entscheiden, personenbezogene Daten mit den Eigentümern von Marken zu teilen, die unsere Laava-Fingerabdrücke (Marken) verwenden. Dies wird auch in unserem Datenschutzmanifest behandelt.",
  privacyPolicyP7:
    "Es gibt jedoch einige begrenzte Umstände, unter denen wir Ihre personenbezogenen Daten direkt von Ihnen erfassen müssen, einschließlich wenn Sie:",
  privacyPolicyP8:
    "mit uns per Telefon, E-Mail, online (einschließlich über unsere Website oder unseren Helpdesk) oder persönlich kommunizieren",
  privacyPolicyP9: "wenn Sie sich bei uns bewerben",
  privacyPolicyP10: "wenn Sie sich als Investor bewerben; oder",
  privacyPolicyP11:
    "wenn Sie mit uns als potenzieller oder bestehender Kunde, Partner, Lieferant oder Dienstleister interagieren.",
  privacyPolicyP12:
    "Personenbezogene Daten sind alle Informationen oder Meinungen (ob wahr oder nicht und ob in materieller Form aufgezeichnet oder nicht) über Sie als identifizierte Person oder eine Person, die vernünftigerweise identifizierbar ist. Zu den Arten von personenbezogenen Daten, die wir erheben und verarbeiten, können gehören:",
  privacyPolicyP13: "Kontaktinformationen wie Name, Adresse, Telefonnummer, E-Mail- und/oder IP-Adresse",
  privacyPolicyP14:
    "Informationen über Ihre Rolle innerhalb Ihres Unternehmens, wenn Sie ein bestehender oder potenzieller Partner, Kunde, Lieferant oder Dienstleister von uns sind",
  privacyPolicyP15:
    "finanzielle und andere relevante Details, die Sie uns mitteilen, wenn Sie sich als Investor bewerben",
  privacyPolicyP16:
    "Wenn Sie sich bei uns um eine Stelle bewerben, erfassen wir möglicherweise Informationen über Ihre Qualifikationen, Ihre Erfahrung, Ihren Charakter, Ihre Auswahlprüfungen und Ihren beruflichen Werdegang. und",
  privacyPolicyP17:
    "andere Informationen, die Sie uns über unseren Helpdesk zur Verfügung stellen (oder in jeder Anfrage, die Sie an uns senden).",
  privacyPolicyP18:
    "Wir erheben, speichern, verwenden und geben personenbezogene Daten in erster Linie weiter, um auf Anfragen zu antworten, Ihre Bewerbung für eine Stelle bei uns zu prüfen oder Sie als Investor, Kunde, Lieferant oder Partner von uns zu betrachten. Wir können Ihre personenbezogenen Daten auch aus verwandten Gründen erfassen, einschließlich:",
  privacyPolicyP19: "Verwaltungszwecke, einschließlich Aufzeichnungen und interner Berichterstattung",
  privacyPolicyP20: "mit Ihnen zu kommunizieren",
  privacyPolicyP21: "für Marketingzwecke; und",
  privacyPolicyP22: "um geltende Gesetze und Vorschriften einzuhalten.",
  privacyPolicyP23:
    "Unter den in Abschnitt 2 oben aufgeführten begrenzten Umständen, in denen wir Ihre personenbezogenen Daten erfasst haben, können wir Ihre personenbezogenen Daten an Dritte weitergeben. Zu den Arten von Drittparteien, mit denen wir personenbezogene Daten austauschen, gehören:",
  privacyPolicyP24:
    "unsere Dienstleister, einschließlich Buchhaltung, Wirtschaftsprüfung, Rechtswesen, Bankwesen, Zahlung, Lieferung, Datenhosting, Recherche, Personalbeschaffung, Versicherung, Altersvorsorge, Gehaltsabrechnung, Schulung und Technologiedienste",
  privacyPolicyP25:
    "für Personen, die sich bewerben, um bei uns zu arbeiten, Referenzen und Screening-Anbieter ( z . B. für Hintergrund-, Arbeitsfähigkeits-, Eignungs- und Vorstrafenprüfungen); und",
  privacyPolicyP26:
    "an Strafverfolgungsbehörden und andere Organisationen, sofern dies gesetzlich vorgeschrieben oder zulässig ist.",
  privacyPolicyP27:
    "Wir werden Ihre personenbezogenen Daten nicht an ausländische Empfänger weitergeben. Wir können Ihre personenbezogenen Daten jedoch bei sicheren Datenhostinganbietern wie Google speichern, die diese Informationen an den folgenden Orten speichern können: ({{link}}). Wenn möglich, werden wir verlangen, dass Ihre personenbezogenen Daten in Australien gespeichert bleiben. Wenn wir Ihre personenbezogenen Daten an Empfänger im Ausland weitergeben müssen, werden wir diese Datenschutzrichtlinie aktualisieren, indem wir die Länder angeben, in denen sie sich befinden.",
  privacyPolicyP28:
    "Wenn Sie uns die personenbezogenen Daten bei Bedarf nicht zur Verfügung stellen, sind wir möglicherweise nicht in der Lage:",
  privacyPolicyP29: "Betrachten Sie Ihre Bewerbung als Mitarbeiter bei uns",
  privacyPolicyP30: "Betrachten Sie Ihre Bewerbung als Investor, Kunde, Partner; Lieferant oder Dienstleister; und",
  privacyPolicyP31: "Beantwortung Ihrer Anfragen oder Informationsanfragen.",
  privacyPolicyP32:
    "Ein Browser-Cookie ist ein kleines Datenelement, das auf Ihrem Gerät gespeichert wird, um Websites und mobilen Anwendungen zu helfen, sich Dinge über Sie zu merken. Obwohl wir in der mobilen Laava-App keine Cookies verwenden, verwenden wir Cookies auf unserer Website. Cookies helfen uns zu sehen, welche Teile unserer Website am beliebtesten sind, was es uns ermöglicht, uns auf die Verbesserung der Aspekte der Website zu konzentrieren, die Ihre Erfahrung verbessern, und Ihnen eine bessere, intuitivere und zufriedenstellendere Erfahrung zu bieten. Ihr Browser oder Gerät kann es Ihnen ermöglichen, die Verwendung von Cookies zu blockieren oder anderweitig einzuschränken. Möglicherweise können Sie Cookies auch aus Ihrem Browser entfernen.",
  privacyPolicyP33:
    "Um den Service zu verbessern, den wir über unsere Website anbieten, können bestimmte Informationen von Ihrem Browser erfasst werden. Diese Informationen werden als statistische Informationen gesammelt und umfassen die IP-Adresse, den Browsertyp, die Sprache und die Zugriffszeiten. In vielen Fällen zeichnen die auf unserer Website verwendeten Tools Informationen auf eine Weise auf, die Sie nicht als Einzelperson identifizieren. In anderen Fällen kann es sich bei Informationen um personenbezogene Daten handeln, in denen diese Datenschutzrichtlinie Anwendung findet.",
  privacyPolicyP34:
    "Unsere Website kann von Zeit zu Zeit auch Website-Tools von Drittanbietern verwenden, z. B. Google Analytics und Online-Umfragetools. Nähere Informationen zur Erhebung und Verarbeitung von Daten durch Google finden Sie in der Datenschutzerklärung von Google und deren Informationen unter: ( {{ link }}).",
  privacyPolicyP35:
    "Wir unternehmen alle angemessenen Schritte, um personenbezogene Daten vor Verlust, Eingriffen, Missbrauch oder unbefugtem Zugriff, Änderung oder Offenlegung zu schützen. Diese Schritte können Zugangskontrollen, Verschlüsselung und sichere Räumlichkeiten umfassen. Wir speichern personenbezogene Daten sowohl in physischer als auch in elektronischer Form, manchmal mit Unterstützung von Dritten wie Datenhostinganbietern.",
  privacyPolicyP36:
    "Wenn Sie zugestimmt haben, Marketinginformationen von uns zu erhalten, können wir Ihnen von Zeit zu Zeit Direktmarketing-Mitteilungen zu unseren Dienstleistungen zusenden.",
  privacyPolicyP37:
    "Wir können Sie per elektronischer Nachricht ( z . B. E-Mail), online (z. B. über unsere Website), per Post und auf andere Weise kontaktieren, es sei denn, Sie widersprechen später oder wir unterliegen gesetzlichen Beschränkungen. Sie können sich von Marketingmitteilungen abmelden, indem Sie uns über die unten stehenden Kontaktdaten kontaktieren.",
  privacyPolicyP38:
    "gespeicherten und verwendeten personenbezogenen Daten ist uns wichtig. Wir unternehmen angemessene Schritte, um sicherzustellen, dass die von uns verarbeiteten personenbezogenen Daten korrekt, vollständig und aktuell sind . Um uns zu helfen, Ihre persönlichen Daten korrekt zu halten, teilen Sie uns bitte mit, wenn es Fehler oder Änderungen in Ihren persönlichen Daten gibt.",
  privacyPolicyP39:
    "Sie können jederzeit Zugang zu und Berichtigung der personenbezogenen Daten, die wir über Sie gespeichert haben, anfordern, indem Sie sich über die Kontaktdaten am Ende dieser Datenschutzrichtlinie an unseren Datenschutzbeauftragten wenden. In den meisten Fällen können wir Ihnen bei diesen Anfragen zeitnah und formlos weiterhelfen. In anderen Fällen müssen wir möglicherweise Ihre Identität überprüfen und Sie bitten, Ihre Anfrage schriftlich zu stellen.",
  privacyPolicyP40:
    "Sie können unseren Datenschutzbeauftragten unter Verwendung der Kontaktdaten am Ende dieser Datenschutzrichtlinie kontaktieren, wenn Sie Bedenken darüber haben, wie wir mit Ihren personenbezogenen Daten umgegangen sind. Wir werden Ihnen antworten, um Ihnen mitzuteilen, wer Ihre Angelegenheit bearbeiten wird und wann Sie mit einer weiteren Antwort rechnen können. Wir können von Ihnen zusätzliche Details zu Ihrem Anliegen anfordern und müssen möglicherweise andere Parteien einschalten oder konsultieren, um Ihr Problem zu untersuchen und zu lösen. Wir werden Aufzeichnungen über Ihre Anfrage und jede Lösung führen. Wenn Sie mit der Art und Weise, wie wir Ihre Beschwerde bearbeitet haben, nicht zufrieden sind, können Sie sich an das Büro des australischen Informationsbeauftragten wenden.",
  privacyPolicyP41:
    "Wenn Sie Fragen, Beschwerden oder Ansprüche in Bezug auf den Website-Scanner oder diese Allgemeinen Geschäftsbedingungen haben, können Sie sich an Laava wenden unter:",
  problemsTitle: "Probleme mit dem Fingerabdruck",
  problemsIntro:
    "Wenn der Laava Smart Fingerprint™ beschädigt zu sein scheint oder Sie Probleme beim Scannen haben, können Sie sich an uns wenden und wir werden unser Bestes tun, um Ihnen bei der Lösung des Problems zu helfen.",
  tryScanningAgain: "Versuchen Sie erneut zu scannen",
  emailUs: "Schreiben Sie uns eine E-Mail",
  troubleDetectingFingerprint: "Wir haben Probleme beim Erkennen eines Fingerabdrucks",
  makeSureFingerprint: "Stellen Sie sicher, dass der Fingerabdruck gut beleuchtet und scharf ist",
  ensureInternet: "Stellen Sie sicher, dass Ihre Internetverbindung gut ist",
  tryAgain: "Versuchen Sie es nochmal",
  logIn: "Anmelden",
  account: "Konto",
  emailAddress: "E-Mail-Adresse",
  password: "Passwort",
  submit: "Senden",
  thereWasAProblemLoggingIn: "Es gab ein Problem beim Anmelden. Bitte versuchen Sie es erneut.",
  loggedInAs: "Angemeldet als",
  logOut: "Abmelden"
}

export default de
